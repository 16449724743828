// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetQueriesResponseSaveQuery {
  /** @format int32 */
  id?: number;
  title?: string;
  description?: string;
  query?: V1GetEventsRequest;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface CommonCursorMetadata {
  next?: string;
  prev?: string;
}

export interface CommonPagingMetadata {
  /** @format int64 */
  currentPage?: number;
  /** @format int64 */
  pageSize?: number;
  /** @format int64 */
  totalItems?: number;
  /** @format int64 */
  totalPages?: number;
}

/**
 * `Any` contains an arbitrary serialized protocol buffer message along with a
 * URL that describes the type of the serialized message.
 *
 * Protobuf library provides support to pack/unpack Any values in the form
 * of utility functions or additional generated methods of the Any type.
 *
 * Example 1: Pack and unpack a message in C++.
 *
 *     Foo foo = ...;
 *     Any any;
 *     any.PackFrom(foo);
 *     ...
 *     if (any.UnpackTo(&foo)) {
 *       ...
 *     }
 *
 * Example 2: Pack and unpack a message in Java.
 *
 *     Foo foo = ...;
 *     Any any = Any.pack(foo);
 *     ...
 *     if (any.is(Foo.class)) {
 *       foo = any.unpack(Foo.class);
 *     }
 *     // or ...
 *     if (any.isSameTypeAs(Foo.getDefaultInstance())) {
 *       foo = any.unpack(Foo.getDefaultInstance());
 *     }
 *
 * Example 3: Pack and unpack a message in Python.
 *
 *     foo = Foo(...)
 *     any = Any()
 *     any.Pack(foo)
 *     ...
 *     if any.Is(Foo.DESCRIPTOR):
 *       any.Unpack(foo)
 *       ...
 *
 * Example 4: Pack and unpack a message in Go
 *
 *      foo := &pb.Foo{...}
 *      any, err := anypb.New(foo)
 *      if err != nil {
 *        ...
 *      }
 *      ...
 *      foo := &pb.Foo{}
 *      if err := any.UnmarshalTo(foo); err != nil {
 *        ...
 *      }
 *
 * The pack methods provided by protobuf library will by default use
 * 'type.googleapis.com/full.type.name' as the type URL and the unpack
 * methods only use the fully qualified type name after the last '/'
 * in the type URL, for example "foo.bar.com/x/y.z" will yield type
 * name "y.z".
 *
 * JSON
 *
 * The JSON representation of an `Any` value uses the regular
 * representation of the deserialized, embedded message, with an
 * additional field `@type` which contains the type URL. Example:
 *
 *     package google.profile;
 *     message Person {
 *       string first_name = 1;
 *       string last_name = 2;
 *     }
 *
 *     {
 *       "@type": "type.googleapis.com/google.profile.Person",
 *       "firstName": <string>,
 *       "lastName": <string>
 *     }
 *
 * If the embedded message type is well-known and has a custom JSON
 * representation, that representation will be embedded adding a field
 * `value` which holds the custom JSON in addition to the `@type`
 * field. Example (for message [google.protobuf.Duration][]):
 *
 *     {
 *       "@type": "type.googleapis.com/google.protobuf.Duration",
 *       "value": "1.212s"
 *     }
 */
export interface ProtobufAny {
  /**
   * A URL/resource name that uniquely identifies the type of the serialized
   * protocol buffer message. This string must contain at least
   * one "/" character. The last segment of the URL's path must represent
   * the fully qualified name of the type (as in
   * `path/google.protobuf.Duration`). The name should be in a canonical form
   * (e.g., leading "." is not accepted).
   *
   * In practice, teams usually precompile into the binary all types that they
   * expect it to use in the context of Any. However, for URLs which use the
   * scheme `http`, `https`, or no scheme, one can optionally set up a type
   * server that maps type URLs to message definitions as follows:
   *
   * * If no scheme is provided, `https` is assumed.
   * * An HTTP GET on the URL must yield a [google.protobuf.Type][]
   *   value in binary format, or produce an error.
   * * Applications are allowed to cache lookup results based on the
   *   URL, or have them precompiled into a binary to avoid any
   *   lookup. Therefore, binary compatibility needs to be preserved
   *   on changes to types. (Use versioned type names to manage
   *   breaking changes.)
   *
   * Note: this functionality is not currently available in the official
   * protobuf release, and it is not used for type URLs beginning with
   * type.googleapis.com.
   *
   * Schemes other than `http`, `https` (or the empty scheme) might be
   * used with implementation specific semantics.
   */
  '@type'?: string;
  [key: string]: any;
}

/**
 * `NullValue` is a singleton enumeration to represent the null value for the
 * `Value` type union.
 *
 *  The JSON representation for `NullValue` is JSON `null`.
 *
 *  - NULL_VALUE: Null value.
 * @default "NULL_VALUE"
 */
export enum ProtobufNullValue {
  NULL_VALUE = 'NULL_VALUE',
}

export interface RpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

export interface V1Column {
  name?: string;
  type?: string;
}

export interface V1Condition {
  column?: string;
  operator?: string;
  value?: string;
}

export interface V1GetActiveColumnsResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetActiveColumnsResponseData;
}

export interface V1GetActiveColumnsResponseData {
  columns?: V1Column[];
}

export interface V1GetColumnStatisticRequest {
  columnName?: string;
  appIds?: string[];
  /** @format int64 */
  from?: string;
  /** @format int64 */
  to?: string;
  relation?: string;
  conditions?: V1Condition[];
}

export interface V1GetColumnStatisticResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetColumnStatisticResponseData;
}

export interface V1GetColumnStatisticResponseData {
  items?: V1GetColumnStatisticResponseItem[];
  /** @format uint64 */
  total?: string;
}

export interface V1GetColumnStatisticResponseItem {
  value?: string;
  /** @format int64 */
  count?: number;
}

export interface V1GetEventResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetEventResponseData;
}

export interface V1GetEventResponseData {
  json?: string;
}

export interface V1GetEventSeriesRequest {
  appIds?: string[];
  /** @format int64 */
  from?: string;
  /** @format int64 */
  to?: string;
  relation?: string;
  conditions?: V1Condition[];
  groupBy?: string;
}

export interface V1GetEventSeriesResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetEventSeriesResponseData;
}

export interface V1GetEventSeriesResponseData {
  items?: V1GetEventSeriesResponseItem[];
  /** @format uint64 */
  total?: string;
  groupBy?: string;
}

export interface V1GetEventSeriesResponseItem {
  /** @format uint64 */
  count?: string;
  series?: string;
}

export interface V1GetEventsRequest {
  appIds?: string[];
  /** @format int64 */
  from?: string;
  /** @format int64 */
  to?: string;
  columns?: string[];
  relation?: string;
  conditions?: V1Condition[];
  cursor?: string;
  /** @format int32 */
  limit?: number;
}

export interface V1GetEventsResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetEventsResponseData;
}

export interface V1GetEventsResponseData {
  data?: object[];
  cursor?: CommonCursorMetadata;
}

export interface V1GetInvalidEventsRequest {
  appIds?: string[];
  range?: string;
}

export interface V1GetInvalidEventsResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetInvalidEventsResponseData[];
}

export interface V1GetInvalidEventsResponseData {
  appId?: string;
  eventName?: string;
  screenName?: string;
  reason?: string;
  /** @format uint64 */
  count?: string;
}

export interface V1GetQueriesResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetQueriesResponseData;
}

export interface V1GetQueriesResponseData {
  data?: GetQueriesResponseSaveQuery[];
  paging?: CommonPagingMetadata;
}

export interface V1GetQueryResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1SaveQueryRequest;
}

export interface V1GetSuggestionsRequest {
  columnName?: string;
  appIds?: string[];
  quest?: string;
  /** @format int64 */
  from?: string;
  /** @format int64 */
  to?: string;
}

export interface V1GetSuggestionsResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1GetSuggestionsResponseData;
}

export interface V1GetSuggestionsResponseData {
  items?: V1GetSuggestionsResponseItem[];
}

export interface V1GetSuggestionsResponseItem {
  value?: string;
  /** @format int64 */
  count?: number;
}

export interface V1SaveQueryRequest {
  /** @format int32 */
  id?: number;
  title?: string;
  description?: string;
  query?: V1GetEventsRequest;
}

export interface V1SaveQueryResponse {
  /** @format int64 */
  code?: number;
  message?: string;
  data?: V1SaveQueryResponseData;
}

export interface V1SaveQueryResponseData {
  /** @format int32 */
  id?: number;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title tracking/v1/tracking.proto
 * @version version not set
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetActiveColumns
     * @request GET:/api/v1/columns
     */
    trackingGetActiveColumns: (params: RequestParams = {}) =>
      this.request<V1GetActiveColumnsResponse, RpcStatus>({
        path: `/api/v1/columns`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetColumnStatistic
     * @request POST:/api/v1/columns/statistic
     */
    trackingGetColumnStatistic: (
      body: V1GetColumnStatisticRequest,
      params: RequestParams = {},
    ) =>
      this.request<V1GetColumnStatisticResponse, RpcStatus>({
        path: `/api/v1/columns/statistic`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetSuggestions
     * @request POST:/api/v1/columns/suggestions
     */
    trackingGetSuggestions: (
      body: V1GetSuggestionsRequest,
      params: RequestParams = {},
    ) =>
      this.request<V1GetSuggestionsResponse, RpcStatus>({
        path: `/api/v1/columns/suggestions`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetEventSeries
     * @request POST:/api/v1/event-series
     */
    trackingGetEventSeries: (
      body: V1GetEventSeriesRequest,
      params: RequestParams = {},
    ) =>
      this.request<V1GetEventSeriesResponse, RpcStatus>({
        path: `/api/v1/event-series`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetEvents
     * @request POST:/api/v1/events
     */
    trackingGetEvents: (body: V1GetEventsRequest, params: RequestParams = {}) =>
      this.request<V1GetEventsResponse, RpcStatus>({
        path: `/api/v1/events`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetEvent
     * @request POST:/api/v1/events/{eventId}
     */
    trackingGetEvent: (
      eventId: string,
      body: {
        appIds?: string[];
        date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1GetEventResponse, RpcStatus>({
        path: `/api/v1/events/${eventId}`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetInvalidEvents
     * @request POST:/api/v1/invalid-events
     */
    trackingGetInvalidEvents: (
      body: V1GetInvalidEventsRequest,
      params: RequestParams = {},
    ) =>
      this.request<V1GetInvalidEventsResponse, RpcStatus>({
        path: `/api/v1/invalid-events`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetQueries
     * @request GET:/api/v1/queries
     */
    trackingGetQueries: (
      query?: {
        /** @format int64 */
        pageNum?: number;
        /** @format int64 */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1GetQueriesResponse, RpcStatus>({
        path: `/api/v1/queries`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingSaveQuery
     * @request POST:/api/v1/queries
     */
    trackingSaveQuery: (body: V1SaveQueryRequest, params: RequestParams = {}) =>
      this.request<V1SaveQueryResponse, RpcStatus>({
        path: `/api/v1/queries`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingGetQuery
     * @request GET:/api/v1/queries/{id}
     */
    trackingGetQuery: (id: number, params: RequestParams = {}) =>
      this.request<V1GetQueryResponse, RpcStatus>({
        path: `/api/v1/queries/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
}
