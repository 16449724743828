const PAGE_SIZE_OPTIONS = [10, 15, 20, 50, 100];

const DEFAULT_SIZE_CHANGER = {
  showSizeChanger: true,
  pageSizeOptions: PAGE_SIZE_OPTIONS.map((size) => String(size)),
  locale: {
    items_per_page: 'RecordsPerPage',
  },
};

export default {
  PAGE_SIZE_OPTIONS,
  DEFAULT_SIZE_CHANGER,
};
