export const REACT_APP_BASE_API_URL: string =
  (window as any).__VARIABLES__?.REACT_APP_BASE_API_URL ||
  'http://localhost:4000';

export const REACT_APP_EVENT_API_URL: string =
  (window as any).__VARIABLES__?.REACT_APP_EVENT_API_URL ||
  'http://localhost:14080';

export const REACT_APP_KIBANA_URL: string =
  (window as any).__VARIABLES__?.REACT_APP_KIBANA_URL || window.location.href;

export const REACT_APP_TRACKING_APPLICATION: string = (window as any)
  .__VARIABLES__?.REACT_APP_TRACKING_APPLICATION;
